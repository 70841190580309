import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@iq/react-components';

import { submitVariable, removeVariable } from '../../bundles/components';
import { resetGlobalError } from '../../bundles/errors';

import Modal from '../Modal';

const VariableModal = ({
  remove,
  error,
  baseVariable,
  submitVariable: submitData,
  removeVariable: submitRemove,
  resetGlobalError: resetError,
  onClose,
}) => {
  const { id } = baseVariable || {};
  const [variable, setVariable] = useState(
    baseVariable ? JSON.stringify(baseVariable, null, 2) : ''
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const canSubmit = Boolean(variable) || Boolean(remove);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      resetError();
    };
  }, []);

  const doSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submitData(JSON.parse(variable));
    }
  };

  const doEdit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submitData(JSON.parse(variable));
    }
  };

  const doRemove = () => {
    setIsSubmitting(true);
    submitRemove(remove);
  };

  if (error !== null && error.length > 0 && isSubmitting) {
    setIsSubmitting(false);
  }

  if (remove) {
    return (
      <Modal
        isOpen
        style="slim"
        onClose={onClose}
        title="Remove variable"
      >
        <section className="variable-modal">
          {error !== null && error.length > 0 && (
            <div className="variable--modal-message">
              {error.length > 0 && error.map((msg) => <p key={msg}>{msg}</p>)}
            </div>
          )}
          <p>{remove.variableId}</p>
          <br />
          <div className="variable--modal-form">
            <p>Confirm to remove this variable?</p>
          </div>
          <br></br>
          <Button
            onClick={doRemove}
            disabled={!canSubmit}
          >
            Remove variable
          </Button>
        </section>
      </Modal>
    );
  }
  return (
    <Modal
      isOpen
      style="slim"
      onClose={onClose}
      title={id ? 'Edit variable' : 'Add variable'}
    >
      <section className="variable-modal">
        {error !== null && error.length > 0 && (
          <div className="variable--modal-message">
            {error.length > 0 && error.map((msg) => <p key={msg}>{msg}</p>)}
          </div>
        )}
        <div className="variable--modal-form">
          <label className="variable--modal-field required">
            <span>Variable:</span>
            <textarea
              disabled={isSubmitting}
              required
              name="variable_model"
              value={variable}
              onChange={(e) => setVariable(e.target.value)}
            ></textarea>
          </label>
        </div>
        <br />
        <Button
          onClick={() => (id ? doEdit() : doSubmit())}
          disabled={!canSubmit}
        >
          {id ? 'Save' : 'Add'}
        </Button>
      </section>
    </Modal>
  );
};

function mapStateToProps({ errors: { error } }) {
  let messages = [];
  if (error && error !== null && error.message) {
    messages = [`${error.message.message}: `];
    if (
      error.errors &&
      error.errors.errors &&
      error.errors.errors.body &&
      error.errors.errors.body.length
    ) {
      messages = [...messages, ...error.errors.errors.body.map((item) => item.message)];
    }
  }
  return {
    error: messages.length ? messages : '',
  };
}

const mapDispatchToProps = {
  submitVariable,
  removeVariable,
  resetGlobalError,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariableModal);
