import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { AuthorizationProvider, useAuth } from '@iq/react-components';

import { requestGrants as requestGrantsMethod } from '../../bundles/auth';

const { AuthProvider } = AuthorizationProvider;

const AppFrame = ({ children, requestGrants, grants }) => {
  const { subject } = useAuth();
  useEffect(() => {
    requestGrants(subject);
  }, []);

  if (grants === null) {
    return 'Loading ...';
  }

  return (
    <AuthProvider value={{ grants }}>
      <section className="app-frame-component">{children}</section>
    </AuthProvider>
  );
};

const mapDispatchToProps = {
  requestGrants: requestGrantsMethod,
};

const mapStateToProps = (state) => ({
  grants: state.auth.grants,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppFrame);
