import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@iq/react-components';

import { submitSource, deleteSource } from '../../bundles/sources';
import { resetGlobalError } from '../../bundles/errors';

import Modal from '../Modal';

const SourceModal = ({
  sourceId,
  data,
  remove,
  error,
  submitSource: submitData,
  removeSource: submitRemove,
  submitSuccess,
  deleteSuccess,
  onSubmitSuccess,
  onRemoveSuccess,
  resetGlobalError: resetError,
  onClose,
}) => {
  const [validationError, setValidationError] = useState(null);
  const { id, ...defaultLayout } = data || {};
  const [layout, setLayout] = useState(defaultLayout ? JSON.stringify(defaultLayout, null, 2) : '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const canSubmit = Boolean(layout);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      resetError();
    };
  }, []);

  const doSubmit = () => {
    setValidationError(null);
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        submitData(JSON.parse(layout));
      } catch (e) {
        setIsSubmitting(false);
        if (e.name === 'SyntaxError') {
          setValidationError('Invalid json content');
        }
      }
    }
  };

  const doEdit = () => {
    setValidationError(null);

    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        submitData({
          ...JSON.parse(layout),
          id,
        });
      } catch (e) {
        setIsSubmitting(false);
        if (e.name === 'SyntaxError') {
          setValidationError('Invalid json content');
        }
      }
    }
  };

  const doRemove = () => {
    setIsSubmitting(true);
    submitRemove(sourceId, data.id);
  };

  if (submitSuccess !== null) {
    onSubmitSuccess(submitSuccess);
  }

  if (deleteSuccess !== null) {
    onRemoveSuccess(deleteSuccess);
  }

  if (error !== null && error.length > 0 && isSubmitting) {
    setIsSubmitting(false);
  }

  if (remove) {
    return (
      <Modal
        isOpen
        style="slim"
        onClose={onClose}
        title="Remove source"
      >
        <section className="source-modal">
          {error !== null && error.length > 0 && (
            <div className="source--modal-message">
              {error.length > 0 && error.map((msg) => <p key={msg}>{msg}</p>)}
            </div>
          )}
          <div className="source--modal-form">
            <p>Confirm to remove this source?</p>
          </div>
          <br></br>
          <Button
            onClick={doRemove}
            disabled={!canSubmit}
          >
            Remove Layout
          </Button>
        </section>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      style="slim"
      onClose={onClose}
      title={data ? 'Edit Layout' : 'Add source'}
    >
      <section className="source-modal">
        {error !== null && error.length > 0 && (
          <div className="source--modal-message">
            {error.length > 0 && error.map((msg) => <p key={msg}>{msg}</p>)}
          </div>
        )}
        {validationError !== null && <div className="source--modal-message">{validationError}</div>}
        <div className="source--modal-form">
          <label className="source--modal-field required">
            <span>Source:</span>
            <textarea
              disabled={isSubmitting}
              required
              name="source_model"
              value={layout}
              onChange={(e) => setLayout(e.target.value)}
            ></textarea>
          </label>
        </div>
        <br></br>
        <Button
          onClick={() => (data ? doEdit() : doSubmit())}
          disabled={!canSubmit}
        >
          {data ? 'Save' : 'Add'}
        </Button>
      </section>
    </Modal>
  );
};

function mapStateToProps({ errors: { error }, sources: { submitSuccess, deleteSuccess } }) {
  let messages = [];
  if (error && error !== null && error.message) {
    messages = [`${error.message.message}: `];
    if (
      error.errors &&
      error.errors.errors &&
      error.errors.errors.body &&
      error.errors.errors.body.length
    ) {
      messages = [...messages, ...error.errors.errors.body.map((item) => item.message)];
    }
  }
  return {
    submitSuccess,
    deleteSuccess,
    error: messages.length ? messages : '',
  };
}

const mapDispatchToProps = {
  submitSource,
  deleteSource,
  resetGlobalError,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceModal);
