import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Can } from '@iq/react-components';

import { resetSiteState, requestSite } from '../../bundles/sites';
import { resetLayoutsState } from '../../bundles/layouts';

import SectionHeader from '../SectionHeader';
import BackLink from '../BackLink';
import PageHeader from '../PageHeader';
import SiteModal from '../SiteModal';
import SitePropertyModal from '../SitePropertyModal';
import SiteModelSelector from '../SiteModelSelector';
import SiteLayoutModal from '../SiteLayoutModal';

const SiteDetail = ({
  id,
  isLoading,
  site,
  resetSiteState: doResetSiteState,
  resetLayoutsState: doResetLayoutsState,
  requestSite: getSite,
}) => {
  const [modalEditOpen, setModalEditOpen] = useState(false);

  const [modalLayoutAddOpen, setLayoutAddOpen] = useState(false);
  const [layoutEdit, setLayoutEdit] = useState(undefined);
  const [layoutRemove, setLayoutRemove] = useState(undefined);

  const [modalPropertyAddOpen, setPropertyAddOpen] = useState(false);
  const [propertyEdit, setPropertyEdit] = useState(undefined);
  const [propertyRemove, setPropertyRemove] = useState(undefined);

  const [modalAssignOpen, setModelAssignOpen] = useState(false);

  useEffect(() => {
    doResetSiteState();
    getSite(id);
  }, [id]);

  if (isLoading) {
    return 'Loading...';
  }

  if (!id || site === null) {
    return (
      <section>
        <BackLink
          title={'Back to sites'}
          to={'/sites'}
        />
        <PageHeader title={'404 - Site not found'} />
      </section>
    );
  }
  const refreshDetails = () => {
    setTimeout(() => {
      doResetSiteState();
      doResetLayoutsState();
      getSite(id);
    }, 200);
  };

  const onEditSuccess = () => {
    setModalEditOpen(false);
    refreshDetails();
  };

  const onModelAssign = () => {
    setModelAssignOpen(false);
    refreshDetails();
  };

  const onLayoutAdd = () => {
    setLayoutAddOpen(false);
    refreshDetails();
  };

  const onLayoutEdit = () => {
    setLayoutEdit(undefined);
    refreshDetails();
  };

  const onLayoutRemove = () => {
    setLayoutRemove(undefined);
    refreshDetails();
  };

  const onPropertyAdd = () => {
    setPropertyAddOpen(false);
    refreshDetails();
  };

  const onPropertyEdit = () => {
    setPropertyEdit(undefined);
    refreshDetails();
  };

  const onPropertyRemove = () => {
    setPropertyRemove(undefined);
    refreshDetails();
  };

  return (
    <section>
      <BackLink
        title={'Back to sites'}
        to={'/sites'}
      />
      <SectionHeader title="">
        <Can permission="permissions/Write">
          {site.org && Boolean(site.org.length) && (
            <Button onClick={() => setModelAssignOpen(true)}>Assign Model</Button>
          )}
          <Button onClick={() => setPropertyAddOpen(true)}>Add property</Button>
          <Button onClick={() => setLayoutAddOpen(true)}>Add Layout</Button>
          <Button onClick={() => setModalEditOpen(true)}>Edit site</Button>
        </Can>
      </SectionHeader>

      {modalEditOpen && (
        <SiteModal
          data={site}
          onClose={() => setModalEditOpen(false)}
          onSubmitSuccess={onEditSuccess}
        />
      )}

      {modalAssignOpen && (
        <SiteModelSelector
          data={site}
          onClose={() => setModelAssignOpen(false)}
          onSubmitSuccess={onModelAssign}
        />
      )}

      {modalLayoutAddOpen && (
        <SiteLayoutModal
          siteId={site.id}
          onClose={() => setLayoutAddOpen(false)}
          onSubmitSuccess={onLayoutAdd}
        />
      )}

      {layoutEdit && (
        <SiteLayoutModal
          data={layoutEdit}
          siteId={site.id}
          onClose={() => setLayoutEdit(undefined)}
          onSubmitSuccess={onLayoutEdit}
        />
      )}

      {layoutRemove && (
        <SiteLayoutModal
          remove
          data={layoutRemove}
          siteId={site.id}
          onClose={() => setLayoutRemove(undefined)}
          onRemoveSuccess={onLayoutRemove}
        />
      )}

      {modalPropertyAddOpen && (
        <SitePropertyModal
          siteId={site.id}
          onClose={() => setPropertyAddOpen(false)}
          onSubmitSuccess={onPropertyAdd}
        />
      )}

      {propertyEdit && (
        <SitePropertyModal
          data={propertyEdit}
          siteId={site.id}
          onClose={() => setPropertyEdit(undefined)}
          onSubmitSuccess={onPropertyEdit}
        />
      )}

      {propertyRemove && (
        <SitePropertyModal
          remove
          data={propertyRemove}
          siteId={site.id}
          onClose={() => setPropertyRemove(undefined)}
          onRemoveSuccess={onPropertyRemove}
        />
      )}

      <div className="site-detail">
        <ul className="site-detail-items">
          <li>
            <span>ID: </span>
            <span>{site.id}</span>
          </li>
          <li>
            <span>Site ID: </span>
            <span>{site.siteId}</span>
          </li>
          <li>
            <span>Name: </span>
            <span>{site.name}</span>
          </li>
          <li>
            <span>Org: </span>
            <span>{site.org}</span>
          </li>
          <li>
            <span>Customer: </span>
            <span>{site.customer}</span>
          </li>
          <li>
            <span>City: </span>
            <span>{site.location.city}</span>
          </li>
          <li>
            <span>Country: </span>
            <span>{site.location.country}</span>
          </li>
          <li>
            <span>Geolocation: </span>
            <span>
              {site.location.lat}, {site.location.lng}
            </span>
          </li>
          <li>
            <span>Model: </span>
            <span>{site.model}, </span>
            <span>Version: {site.version || 'no version selected'}</span>
          </li>
          <li>
            <span>Properties: </span>
            {(!site.properties || site.properties.length === 0) && <span>No properties</span>}
            {Boolean(site.properties.length) && (
              <ul className="site-detail-subitems">
                {site.properties.map((item) => (
                  <li key={`layout-${item.id}`}>
                    <div className="site-detail--actions">
                      <Button
                        activity="secondary"
                        design="outlined"
                        onClick={() => setPropertyEdit(item)}
                      >
                        Edit {item.attribute}
                      </Button>
                      <Button
                        activity="secondary"
                        design="outlined"
                        onClick={() => setPropertyRemove(item)}
                      >
                        Remove
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li>
            <span>Layouts: </span>
            {(!site.layouts || site.layouts.length === 0) && <span>No layouts</span>}
            {Boolean(site.layouts.length) && (
              <ul className="site-detail-subitems">
                {site.layouts.map((item) => (
                  <li key={`layout-${item.id}`}>
                    <div className="site-detail--actions">
                      <Button
                        activity="secondary"
                        design="outlined"
                        onClick={() => setLayoutEdit(item)}
                      >
                        Edit {item.id}
                      </Button>
                      <Button
                        activity="danger"
                        design="outlined"
                        onClick={() => setLayoutRemove(item)}
                      >
                        Remove
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        </ul>
      </div>
    </section>
  );
};

const mapStateToProps = ({ sites: { isLoading, site } }, { match: { params } }) => ({
  id: params && params.id ? params.id : undefined,
  site,
  isLoading,
});

const mapDispatchToProps = {
  requestSite,
  resetSiteState,
  resetLayoutsState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetail);
