const css = `.permissions-modal .permission-values-header {
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgb(242.25, 242.25, 242.25);
  font-weight: 600;
}
.permissions-modal .permission-value-list-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.5em;
  border-bottom: 1px solid rgb(242.25, 242.25, 242.25);
  align-items: center;
}
.permissions-modal .permission-value-list-item *:first-child {
  flex: 1;
}
.permissions-modal .add-permission-form {
  display: flex;
  align-items: center;
  margin: 1em 0;
}
.permissions-modal .add-permission-form .field {
  margin: 0;
  width: 100%;
}
.permissions-modal .add-permission-form .submit {
  white-space: nowrap;
  margin-left: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvUGVybWlzc2lvbk1vZGFsIiwic291cmNlcyI6WyJQZXJtaXNzaW9uTW9kYWwuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUlKO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwiQGlxL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2Nzc1wiO1xuXG4ucGVybWlzc2lvbnMtbW9kYWwge1xuICAucGVybWlzc2lvbi12YWx1ZXMtaGVhZGVyIHtcbiAgICBwYWRkaW5nLWJvdHRvbTogMC41ZW07XG4gICAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkICRHcmV5NTtcbiAgICBmb250LXdlaWdodDogNjAwO1xuICB9XG5cbiAgLnBlcm1pc3Npb24tdmFsdWUtbGlzdC1pdGVtIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXg6IDE7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICBwYWRkaW5nOiAwLjVlbTtcbiAgICBib3JkZXItYm90dG9tOiAxcHggc29saWQgJEdyZXk1O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgICAqOmZpcnN0LWNoaWxkIHtcbiAgICAgIGZsZXg6IDE7XG4gICAgfVxuICB9XG5cbiAgLmFkZC1wZXJtaXNzaW9uLWZvcm0ge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBtYXJnaW46IDFlbSAwO1xuXG4gICAgLmZpZWxkIHtcbiAgICAgIG1hcmdpbjogMDtcbiAgICAgIHdpZHRoOiAxMDAlO1xuICAgIH1cblxuICAgIC5zdWJtaXQge1xuICAgICAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbiAgICAgIG1hcmdpbi1sZWZ0OiAxcmVtO1xuICAgIH1cbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
