import React from 'react';
// import axios from 'axios';
// import { getAccessToken } from '@iq/react-components';

// const URL = 'https://graph.microsoft.com/beta/me/photos/48x48/$value';

class ProfilePhoto extends React.Component {
  state = {
    src: '/images/profile.png',
  };

  // async componentDidMount() {
  //   const token = await getAccessToken('User.Read');
  //
  //   try {
  //     const resp = await axios.get(URL, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       responseType: 'blob',
  //     });
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const src = reader.result;
  //       this.setState({ src });
  //     };
  //     reader.readAsDataURL(resp.data);
  //   } catch (err) {
  //     // 501 || 404 == no profile image
  //   }
  // }

  render() {
    const { src } = this.state;

    return <img src={src} />;
  }
}

export default ProfilePhoto;
