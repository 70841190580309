import React from 'react';
import { Button } from '@iq/react-components';

import Modal from '../Modal';

class EntityModal extends React.Component {
  state = {
    eref: '',
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { eid } = this.state;
    this.props.onEntityAdd(eid);
  };

  render() {
    const { eid } = this.state;
    const { etype } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        style="slim"
        onClose={this.props.onClose}
        title="Add entity"
      >
        <section className="permissions-modal">
          <form
            className="permission-form"
            onSubmit={this.onSubmit}
          >
            <div className="field">
              {etype}/
              <input
                name="eid"
                defaultValue={eid}
                required
                onChange={(e) => this.setState({ eid: e.target.value })}
              />
            </div>
            <div className="submit">
              <Button>Add entity</Button>
            </div>
          </form>
        </section>
      </Modal>
    );
  }
}

export default EntityModal;
