/* eslint-disable default-param-last */
import { takeLatest } from 'redux-saga/effects';
import { ForbiddenError } from '@iq/services';

const GLOBAL_ERROR = 'admin/errors/ERROR';
const GLOBAL_ERROR_RESET = 'admin/errors/RESET';

export function handleGlobalError(error) {
  return {
    type: GLOBAL_ERROR,
    error,
  };
}

export function resetGlobalError() {
  return {
    type: GLOBAL_ERROR_RESET,
  };
}

// Reducer

const initialState = {
  error: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case GLOBAL_ERROR_RESET:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

// Sagas

// eslint-disable-next-line require-yield
function* doHandleGlobalError(action) {
  console.log('global error', action);
  if (action.error instanceof ForbiddenError) {
    console.log('You do not have access!');
  } else {
    console.log('An error occurred. Please try again soon.');
  }
}

export const sagas = [takeLatest(GLOBAL_ERROR, doHandleGlobalError)];
