import React from 'react';
import { getAccessToken, useAuth } from '@iq/react-components';

import { createServiceClient } from '../../services';

import SectionHeader from '../SectionHeader';
import BackLink from '../BackLink';

class Profile extends React.Component {
  state = {
    permissions: null,
  };

  componentDidMount() {
    this.loadGrants();
  }

  async loadGrants() {
    const token = await getAccessToken('auth');
    const client = createServiceClient('auth', token);
    const { subject } = useAuth();

    const object = `users/${subject}`;
    const { permissions } = await client.auth.grants(object);
    this.setState({
      permissions,
    });
  }

  render() {
    if (this.state.permissions === null) {
      return <div>Loading...</div>;
    }

    return (
      <section className="profile-component">
        <BackLink
          title="Back to Home"
          to="/"
        />
        <SectionHeader title="Profile" />
        <div className="permissions">
          {Object.keys(this.state.permissions).map((key) => (
            <div
              className="permission"
              key={key}
            >
              <span className="name">{key}</span>{' '}
              <span className="value">{String(this.state.permissions[key])}</span>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default Profile;
