const css = `.site-table .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.site-table .actions a {
  display: inline-block;
}

.sites--modal-actions {
  margin-top: 1rem;
}
.sites--modal-actions button {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.sites--modal-actions button.action.cancel {
  background: #ff000f;
  border: 1px solid #ff000f;
  color: white;
}
.sites--modal-actions button:disabled, .sites--modal-actions button.disabled {
  background: grey;
  border-color: lightgrey;
  opacity: 0.4;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZVRhYmxlIiwic291cmNlcyI6WyJTaXRlVGFibGUuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7OztBQUtOO0VBQ0U7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBRUU7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIkBpcS9yZWFjdC1jb21wb25lbnRzL2Rpc3QvY29sb3JzLnNjc3NcIjtcblxuLnNpdGUtdGFibGUge1xuICAuYWN0aW9ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAganVzdGlmeS1jb250ZW50OiBmbGV4LWVuZDtcblxuICAgIGEge1xuICAgICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAgIH1cbiAgfVxufVxuXG4uc2l0ZXMtLW1vZGFsLWFjdGlvbnMge1xuICBtYXJnaW4tdG9wOiAxcmVtO1xuXG4gIGJ1dHRvbiB7XG4gICAgZm9udC1zaXplOiAxcmVtO1xuICAgIG1hcmdpbi1yaWdodDogMC41cmVtO1xuXG4gICAgJi5hY3Rpb24uY2FuY2VsIHtcbiAgICAgIGJhY2tncm91bmQ6ICNmZjAwMGY7XG4gICAgICBib3JkZXI6IDFweCBzb2xpZCAjZmYwMDBmO1xuICAgICAgY29sb3I6IHdoaXRlO1xuICAgIH1cblxuICAgICY6ZGlzYWJsZWQsXG4gICAgJi5kaXNhYmxlZCB7XG4gICAgICBiYWNrZ3JvdW5kOiBncmV5O1xuICAgICAgYm9yZGVyLWNvbG9yOiBsaWdodGdyZXk7XG4gICAgICBvcGFjaXR5OiAwLjQ7XG4gICAgfVxuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
