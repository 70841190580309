import React from 'react';
import { connect } from 'react-redux';
import { Button, Can } from '@iq/react-components';

import AddRoleModal from '../AddRoleModal';
import EmptyState from '../EmptyState';
import SectionHeader from '../SectionHeader';
import Entity from '../Entity';

const getEntityName = (type, entity) => {
  console.log(type, entity);
  if (type === 'groups' && entity) {
    return entity.path;
  }

  if (type === 'users' && entity) {
    return entity.name;
  }

  return '-';
};

class ConnectionsTable extends React.Component {
  state = {
    modal: {
      isOpen: false,
    },
  };

  openModal = () => {
    this.setState({ modal: { isOpen: true } });
  };

  closeModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        isOpen: false,
      },
    });
  };

  addConnection = (connection) =>
    this.props.addConnection(this.props.etype, this.props.eid, connection);

  removeConnection = (connection) =>
    this.props.removeConnection(this.props.etype, this.props.eid, connection);

  render() {
    const { eid, etype, connections, users, groups, title } = this.props;
    const eref = `${etype}/${eid}`;

    return (
      <div className="connections-table">
        <SectionHeader title={title}>
          {this.props.addConnection && (
            <Can
              permission="permissions/Write"
              scope={{ entity: eref }}
            >
              <Button
                activity="info"
                onClick={this.openModal}
              >
                Add connection
              </Button>
            </Can>
          )}
        </SectionHeader>

        {connections.length ? (
          <table>
            <thead>
              <tr>
                <th>Entity</th>
                <th colSpan="2">Name</th>
              </tr>
            </thead>
            <tbody>
              {connections.sort().map((connection) => {
                const [connectionType, connectionEid] = connection.split('/');
                return (
                  <tr key={`connection-${connection}`}>
                    <td>
                      <Entity eref={connection} />
                    </td>
                    <td>
                      {getEntityName(connectionType, users[connectionEid] || groups[connectionEid])}
                    </td>
                    <td className="actions">
                      {this.props.removeConnection && (
                        <Can
                          permission="permissions/Write"
                          scope={{ entity: eref }}
                        >
                          <Button
                            activity="danger"
                            design="outlined"
                            onClick={() => this.removeConnection(connection)}
                          >
                            Remove
                          </Button>
                        </Can>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <EmptyState>{eid} is not connected to any entities.</EmptyState>
        )}

        <AddRoleModal
          isOpen={this.state.modal.isOpen}
          onClickClose={() => this.closeModal()}
          onClickAdd={(role) => {
            this.addConnection(role);
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

function mapStateToProps({ ad: { users, groups } }) {
  return {
    users,
    groups,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsTable);
