const css = `.site-detail .site-detail-items {
  margin: 0;
  padding: 0;
}
.site-detail .site-detail-items li {
  margin-bottom: 0.8rem;
}
.site-detail .site-detail-items li span:first-child {
  font-weight: bolder;
}
.site-detail .site-detail-subitems {
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.site-detail .site-detail-subitems li {
  margin-bottom: 1rem;
}
.site-detail .site-detail--actions {
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
}
.site-detail .site-detail--actions a {
  display: inline-block;
}
.site-detail .site-detail--actions button {
  margin-right: 0.4rem;
  padding: 0.2rem 0.4rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZURldGFpbCIsInNvdXJjZXMiOlsiU2l0ZURldGFpbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdFO0VBQ0U7RUFDQTs7QUFFQTtFQUNFOztBQUVBO0VBQ0U7O0FBSU47RUFDRTtFQUNBOztBQUVBO0VBQ0U7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7QUFHRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwiQGlxL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2Nzc1wiO1xuXG4uc2l0ZS1kZXRhaWwge1xuICAuc2l0ZS1kZXRhaWwtaXRlbXMge1xuICAgIG1hcmdpbjogMDtcbiAgICBwYWRkaW5nOiAwO1xuXG4gICAgbGkge1xuICAgICAgbWFyZ2luLWJvdHRvbTogMC44cmVtO1xuXG4gICAgICBzcGFuOmZpcnN0LWNoaWxkIHtcbiAgICAgICAgZm9udC13ZWlnaHQ6IGJvbGRlcjtcbiAgICAgIH1cbiAgICB9XG4gIH1cbiAgLnNpdGUtZGV0YWlsLXN1Yml0ZW1zIHtcbiAgICBtYXJnaW4tdG9wOiAwLjVyZW07XG4gICAgbWFyZ2luLWxlZnQ6IDFyZW07XG5cbiAgICBsaSB7XG4gICAgICBtYXJnaW4tYm90dG9tOiAxcmVtO1xuICAgIH1cbiAgfVxuXG4gIC5zaXRlLWRldGFpbC0tYWN0aW9ucyB7XG4gICAgbWFyZ2luLXRvcDogMC4ycmVtO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcblxuICAgIGEge1xuICAgICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAgIH1cblxuICAgIGJ1dHRvbiB7XG4gICAgICBtYXJnaW4tcmlnaHQ6IDAuNHJlbTtcbiAgICAgIHBhZGRpbmc6IDAuMnJlbSAwLjRyZW07XG4gICAgfVxuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
