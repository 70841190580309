/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { getAccessToken } from '@iq/react-components';

import { createServiceClient } from '../services';
import { handleGlobalError } from './errors';

export const REQUEST_GRANTS = 'team/auth/REQUEST_GRANTS';
export const RECEIVE_GRANTS = 'team/auth/RECEIVE_GRANTS';
export const LOGIN = 'team/auth/LOGIN';
export const LOGIN_FAIL = 'team/auth/LOGIN_FAIL';
export const LOGIN_SUCCESS = 'team/auth/LOGIN_SUCCESS';
export const LOGIN_INVALID = 'team/auth/LOGIN_INVALID';
export const LOGOUT = 'team/auth/LOGOUT';
export const LOGOUT_SUCCESS = 'team/auth/LOGOUT_SUCCESS';

const initialState = {
  grants: null,
  inProgresss: false,
  errors: null,
  authenticated: false,
};

// Action creators

export function requestGrants(oid) {
  return {
    type: REQUEST_GRANTS,
    oid,
  };
}

export function receiveGrants(grants) {
  return {
    type: RECEIVE_GRANTS,
    grants,
  };
}

export function login() {
  return {
    type: LOGIN,
  };
}

export function loginSuccess() {
  return {
    type: LOGIN_SUCCESS,
  };
}

export function loginFail() {
  return {
    type: LOGIN_FAIL,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

// Reducer

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GRANTS: {
      return state;
    }
    case RECEIVE_GRANTS: {
      const { grants } = action;
      return {
        ...state,
        grants,
      };
    }
    default:
      return state;
  }
}

// Sagas

function* doRequestGrants(action) {
  try {
    const { oid } = action;
    const token = yield call(getAccessToken);
    const client = createServiceClient('auth', token);
    const entity = `users/${oid}`;
    const { permissions } = yield call([client.auth, client.auth.grants], entity);
    yield put(receiveGrants(permissions));
  } catch (err) {
    yield put(handleGlobalError(err));
  }
}

export const sagas = [takeLatest(REQUEST_GRANTS, doRequestGrants)];
