import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@iq/react-components';

import { submitComponent, removeComponent } from '../../bundles/components';
import { resetGlobalError } from '../../bundles/errors';

import Modal from '../Modal';

const ComponentModal = ({
  data,
  remove,
  error,
  baseComponent,
  submitComponent: submitData,
  removeComponent: submitRemove,
  resetGlobalError: resetError,
  onClose,
}) => {
  if (data) {
    // eslint-disable-next-line no-param-reassign
    baseComponent = data;
  }
  const [validationError, setValidationError] = useState(null);
  const { id } = data || {};
  const [layout, setLayout] = useState(baseComponent ? JSON.stringify(baseComponent, null, 2) : '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const canSubmit = Boolean(layout);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      resetError();
    };
  }, []);

  const doSubmit = () => {
    setValidationError(null);

    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        submitData(JSON.parse(layout));
      } catch (e) {
        setIsSubmitting(false);
        if (e.name === 'SyntaxError') {
          setValidationError('Invalid json content');
        }
      }
    }
  };

  const doEdit = () => {
    setValidationError(null);

    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        submitData({
          ...JSON.parse(layout),
          id,
        });
      } catch (e) {
        setIsSubmitting(false);
        if (e.name === 'SyntaxError') {
          setValidationError('Invalid json content');
        }
      }
    }
  };

  const doRemove = () => {
    setIsSubmitting(true);
    submitRemove(remove);
  };

  // if (submitSuccess !== null) {
  //   setIsSubmitting(false);
  //   onSubmitSuccess(submitSuccess);
  // }

  // if (deleteSuccess !== null) {
  //   setIsSubmitting(false);
  //   onRemoveSuccess(deleteSuccess);
  // }

  if (error !== null && error.length > 0 && isSubmitting) {
    setIsSubmitting(false);
  }

  if (remove) {
    return (
      <Modal
        isOpen
        style="slim"
        onClose={onClose}
        title="Remove component"
      >
        <section className="component-modal">
          {error !== null && error.length > 0 && (
            <div className="component--modal-message">
              {error.length > 0 && error.map((msg) => <p key={msg}>{msg}</p>)}
            </div>
          )}
          {validationError !== null && (
            <div className="component--modal-message">{validationError}</div>
          )}
          <p>{remove}</p>
          <br />
          <div className="component--modal-form">
            <p>Confirm to remove this component?</p>
          </div>
          <br></br>
          <Button
            onClick={doRemove}
            disabled={!canSubmit}
          >
            Remove component
          </Button>
        </section>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      style="slim"
      onClose={onClose}
      title={data ? 'Edit component' : 'Add component'}
    >
      <section className="component-modal">
        {error !== null && error.length > 0 && (
          <div className="component--modal-message">
            {error.length > 0 && error.map((msg) => <p key={msg}>{msg}</p>)}
          </div>
        )}
        {validationError !== null && (
          <div className="component--modal-message">{validationError}</div>
        )}
        <div className="component--modal-form">
          <label className="component--modal-field required">
            <span>Component:</span>
            <textarea
              disabled={isSubmitting}
              required
              name="component_model"
              value={layout}
              onChange={(e) => setLayout(e.target.value)}
            ></textarea>
          </label>
        </div>
        <br></br>
        <Button
          onClick={() => (data ? doEdit() : doSubmit())}
          disabled={!canSubmit}
        >
          {data ? 'Save' : 'Add'}
        </Button>
      </section>
    </Modal>
  );
};

function mapStateToProps({ errors: { error } }) {
  let messages = [];
  if (error && error !== null && error.message) {
    messages = [`${error.message.message}: `];
    if (
      error.errors &&
      error.errors.errors &&
      error.errors.errors.body &&
      error.errors.errors.body.length
    ) {
      messages = [...messages, ...error.errors.errors.body.map((item) => item.message)];
    }
  }
  return {
    error: messages.length ? messages : '',
  };
}

const mapDispatchToProps = {
  submitComponent,
  removeComponent,
  resetGlobalError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentModal);
