/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@iq/services';
import { getAccessToken } from '@iq/react-components';

import { addLayout, updateLayout, deleteLayout } from '../services';

import { handleGlobalError } from './errors';

// Action types

const RESET_STATE = 'admin/layouts/RESET_STATE';

const SUBMIT_LAYOUT = 'admin/layouts/SUBMIT_LAYOUT';
const SUBMIT_SUCCESS = 'admin/layouts/SUBMIT_SUCCESS';

const DELETE_LAYOUT = 'admin/layouts/DELETE_LAYOUT';
const DELETE_SUCCESS = 'admin/layouts/DELETE_SUCCESS';

// Action creators

export function resetLayoutsState() {
  return {
    type: RESET_STATE,
  };
}

export function submitLayout(siteId, data) {
  return {
    type: SUBMIT_LAYOUT,
    siteId,
    data,
  };
}

export function submitSuccess(id) {
  return {
    type: SUBMIT_SUCCESS,
    id,
  };
}

export function removeLayout(siteId, layoutId) {
  return {
    type: DELETE_LAYOUT,
    siteId,
    layoutId,
  };
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  };
}

// Initial state

const initialState = {
  submitSuccess: null,
  deleteSuccess: null,
};

// Reducer

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case SUBMIT_LAYOUT: {
      return {
        ...state,
        submitSuccess: null,
      };
    }
    case SUBMIT_SUCCESS: {
      return {
        ...state,
        submitSuccess: action.id,
      };
    }
    case DELETE_LAYOUT: {
      return {
        ...state,
        deleteSuccess: false,
      };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
      };
    }

    default: {
      return state;
    }
  }
}

// Sagas

function* doSubmitLayout({ siteId, data }) {
  try {
    if (siteId && data) {
      const token = yield call(getAccessToken);
      let response;
      if (data.id) {
        response = yield updateLayout(token, siteId, data);
      } else {
        response = yield addLayout(token, siteId, data);
      }
      yield put(submitSuccess(response ? response.id : true));
    }
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      console.warn(err);
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doDeleteLayout({ siteId, layoutId }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    yield deleteLayout(token, siteId, layoutId);
    yield put(deleteSuccess());
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      console.warn(err);
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

export const sagas = [
  takeLatest(SUBMIT_LAYOUT, doSubmitLayout),
  takeLatest(DELETE_LAYOUT, doDeleteLayout),
];
