const css = `.source-table .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.source-table .actions a {
  display: inline-block;
}

.sources--modal-actions {
  margin-top: 1rem;
}
.sources--modal-actions button {
  display: inline-block;
  margin-right: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU291cmNlVGFibGUiLCJzb3VyY2VzIjpbIlNvdXJjZVRhYmxlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0U7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOzs7QUFLTjtFQUNFOztBQUVBO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJAaXEvcmVhY3QtY29tcG9uZW50cy9kaXN0L2NvbG9ycy5zY3NzXCI7XG5cbi5zb3VyY2UtdGFibGUge1xuICAuYWN0aW9ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAganVzdGlmeS1jb250ZW50OiBmbGV4LWVuZDtcblxuICAgIGEge1xuICAgICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAgIH1cbiAgfVxufVxuXG4uc291cmNlcy0tbW9kYWwtYWN0aW9ucyB7XG4gIG1hcmdpbi10b3A6IDFyZW07XG5cbiAgYnV0dG9uIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gICAgbWFyZ2luLXJpZ2h0OiAxcmVtO1xuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
