import React from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { Button } from '@iq/react-components';
import logo from '../../../images/logo.svg';
import DropdownMenu from '../DropdownMenu';
import UserBadge from '../UserBadge';

class Header extends React.Component {
  render() {
    const { logout, user } = this.props;

    return (
      <header className="main-header">
        <nav className="site-navigation">
          <Link
            className="logo"
            to="/"
          >
            <img src={logo} />
          </Link>
          <ul className="main-navigation">
            <li>
              <NavLink
                activeClassName="active"
                to={'/entities/users'}
              >
                Users
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to={'/entities/roles'}
              >
                Roles
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to={'/entities/members'}
              >
                Members
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to={'/entities/groups'}
              >
                Groups
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to={'/entities/links'}
              >
                Links
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to={'/scopes'}
              >
                Scopes
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to={'/sites'}
              >
                Sites
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to={'/sources'}
              >
                Sources
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to={'/system-messages'}
              >
                System messages
              </NavLink>
            </li>
          </ul>
        </nav>
        <nav className="account-navigation">
          <ul>
            <li>
              <DropdownMenu header={<UserBadge user={user} />}>
                <div className="profile-link">
                  <Link to="/profile">Profile</Link>
                </div>
                <div className="log-out">
                  <Button
                    onClick={logout}
                    activity="danger"
                  >
                    Log out
                  </Button>
                </div>
              </DropdownMenu>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);
