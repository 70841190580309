import { takeEvery, call, put, all } from 'redux-saga/effects';
import { getAccessToken } from '@iq/react-components';

import { getUser, getGroup } from '../services';

import { RECEIVE_ENTITIES, RECEIVE_ENTITY } from './entities';

const REQUEST_USER = 'team/ad/REQUEST_USER';
const REQUEST_GROUP = 'team/ad/REQUEST_GROUP';
const RECEIVE_USER = 'team/ad/RECEIVE_USER';
const RECEIVE_GROUP = 'team/ad/RECEIVE_GROUP';

export function requestUser(oid) {
  return {
    type: REQUEST_USER,
    oid,
  };
}

export function receiveUser(oid, user) {
  return {
    type: RECEIVE_USER,
    oid,
    user,
  };
}

export function requestGroup(oid) {
  return {
    type: REQUEST_GROUP,
    oid,
  };
}

export function receiveGroup(oid, group) {
  return {
    type: RECEIVE_GROUP,
    oid,
    group,
  };
}

const initialState = {
  users: {},
  groups: {},
  photos: {},
  me: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_USER: {
      const { oid, user } = action;
      return {
        ...state,
        users: {
          ...state.users,
          [oid]: user,
        },
      };
    }
    case RECEIVE_GROUP: {
      const { oid, group } = action;
      return {
        ...state,
        groups: {
          ...state.groups,
          [oid]: group,
        },
      };
    }
    default:
      return state;
  }
}

function* doRequestUser({ oid }) {
  try {
    const token = yield call(getAccessToken);
    const user = yield call(getUser, token, oid);
    yield put(receiveUser(oid, user));
  } catch (err) {
    console.log('Failed to get user', err);
  }
}

function* doRequestGroup({ oid }) {
  try {
    const token = yield call(getAccessToken);
    const group = yield call(getGroup, token, oid);
    yield put(receiveGroup(oid, group));
  } catch (err) {
    console.log('Failed to get group', err);
  }
}

function* doProcessEntities(action) {
  let entities = [];

  if (action.type === RECEIVE_ENTITIES) {
    ({ entities } = action);
  }
  if (action.type === RECEIVE_ENTITY) {
    ({ adConnections: entities } = action.entity);
    const { connected, adConnections, connections } = action.entity;
    const set = new Set([...connected, ...adConnections, ...connections]);
    entities = Array.from(set.values());
  }

  yield all(
    entities
      .map((entity) => {
        const [type, oid] = entity.split('/');
        if (type === 'users') {
          return doRequestUser({ oid });
        }
        if (type === 'groups') {
          return doRequestGroup({ oid });
        }
        return null;
      })
      .filter((x) => !!x)
  );
}

export const sagas = [
  takeEvery(REQUEST_USER, doRequestUser),
  takeEvery(REQUEST_GROUP, doRequestGroup),
  takeEvery([RECEIVE_ENTITIES, RECEIVE_ENTITY], doProcessEntities),
];
