import React from 'react';
import { validateScope } from '@iq/services';
import { Button } from '@iq/react-components';

import Modal from '../Modal';
import Autocomplete from '../Autocomplete';

class AddPermissionModal extends React.Component {
  state = {
    scopeError: false,
    values: {
      name: '',
      scope: '',
    },
    permissions: [
      'comments/Read',
      'comments/Write',
      'components/Read',
      'components/Write',
      'components/Delete',
      'events/Delete',
      'events/Read',
      'events/Write',
      'files/Delete',
      'files/Read',
      'files/Write',
      'jobs/Delete',
      'jobs/Read',
      'jobs/Start',
      'jobs/Write',
      'algorithms/Delete',
      'algorithms/Read',
      'algorithms/Write',
      'markups/Delete',
      'markups/Read',
      'markups/Write',
      'members/Delete',
      'members/Read',
      'members/Write',
      'models/Delete',
      'models/Download',
      'models/Read',
      'models/Write',
      'permissions/Read',
      'permissions/Write',
      'permissions/Delete',
      'projects/Delete',
      'projects/Read',
      'projects/Write',
      'sites/Create',
      'sites/Delete',
      'sites/Read',
      'sites/Write',
      'sources/Delete',
      'sources/Read',
      'sources/Write',
      'tags/Delete',
      'tags/Read',
      'tags/Write',
      'variables/Delete',
      'variables/Read',
      'variables/Write',
      'visualizations/Delete',
      'visualizations/Read',
      'visualizations/Write',
    ],
  };

  ref = React.createRef();

  handleSubmit = (e) => {
    e.preventDefault();

    const { values } = this.state;
    const { name, scope } = values;
    this.setState({ scopeError: false });

    if (this.ref.current.reportValidity()) {
      if (!validateScope(scope)) {
        this.setState({ scopeError: true });
      } else {
        this.props.onAddPermission(name, scope);

        this.setState({
          values: {
            name: '',
            scope: '',
          },
        });
      }
    }
  };

  form = React.createRef();

  render() {
    const { values, permissions, scopeError } = this.state;
    const { name, scope } = values;

    return (
      <Modal
        isOpen={this.props.isOpen}
        style="slim"
        onClose={this.props.onClickClose}
        title="Add permission"
      >
        <section className="add-role-modal">
          <form
            onSubmit={this.handleSubmit}
            ref={this.ref}
          >
            <div className="field">
              <label>Permission name</label>
              <Autocomplete
                getItemValue={(item) => item}
                shouldItemRender={(item, value) =>
                  item.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                inputProps={{
                  placeholder: 'Enter or select permission name ...',
                  required: true,
                }}
                items={permissions}
                value={name}
                onChange={(e) => this.setState({ values: { ...values, name: e.target.value } })}
                onSelect={(value) => this.setState({ values: { ...values, name: value } })}
              />
            </div>
            <div className="field">
              <label>Scope</label>
              <input
                type="text"
                name="scope"
                value={scope}
                placeholder="Enter JSON scope ..."
                required
                onChange={(e) =>
                  this.setState({
                    values: {
                      ...values,
                      scope: e.target.value,
                    },
                  })
                }
              />
              {scopeError && (
                <div className="error">
                  Invalid scope (must be JSON object, all keys strings, only one level of nesting)
                </div>
              )}
            </div>
            <div className="submit">
              <Button type="submit">Add permission</Button>
            </div>
          </form>
        </section>
      </Modal>
    );
  }
}

export default AddPermissionModal;
