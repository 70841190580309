/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@iq/services';
import { getAccessToken } from '@iq/react-components';

import { getSites, getSite, createSite, updateSite, deleteSite as removeSite } from '../services';

import { handleGlobalError } from './errors';

// Action types

const RESET_STATE = 'admin/sites/RESET_STATE';

const REQUEST_SITES = 'admin/sites/REQUEST_SITES';
const RECEIVE_SITES = 'admin/sites/RECEIVE_SITES';

const REQUEST_SITE = 'admin/sites/REQUEST_SITE';
const RECEIVE_SITE = 'admin/sites/RECEIVE_SITE';

const SUBMIT_SITE = 'admin/sites/SUBMIT_SITE';
const SUBMIT_SUCCESS = 'admin/sites/SUBMIT_SUCCESS';

const DELETE_SITE = 'admin/sites/DELETE_SITE';
const DELETE_SUCCESS = 'admin/sites/DELETE_SUCCESS';

// Action creators

export function resetSiteState() {
  return {
    type: RESET_STATE,
  };
}

export function requestSites() {
  return {
    type: REQUEST_SITES,
  };
}

export function receiveSites(sites) {
  return {
    type: RECEIVE_SITES,
    sites,
  };
}

export function requestSite(id) {
  return {
    type: REQUEST_SITE,
    id,
  };
}

export function receiveSite(site) {
  return {
    type: RECEIVE_SITE,
    site,
  };
}

export function submitSite(site) {
  return {
    type: SUBMIT_SITE,
    site,
  };
}

export function submitSuccess(id) {
  return {
    type: SUBMIT_SUCCESS,
    id,
  };
}

export function deleteSite(id) {
  return {
    type: DELETE_SITE,
    id,
  };
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  };
}

// Initial state
const initialState = {
  sitesList: [],
  site: null,
  isLoading: false,
  submitSuccess: null,
  deleteSuccess: null,
};

// Reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case REQUEST_SITES: {
      return {
        ...state,
        sites: [],
        isLoading: true,
      };
    }
    case REQUEST_SITE: {
      return {
        ...state,
        site: null,
        isLoading: true,
      };
    }
    case RECEIVE_SITES: {
      let sitesList = [];
      if (action.sites.length) {
        sitesList = action.sites.map((site) => {
          const newSite = { ...site };
          if (!site.location) {
            newSite.location = {
              city: '',
              country: '',
              lat: 0,
              lng: 0,
            };
          }
          return newSite;
        });
      }
      return {
        ...state,
        sitesList,
        isLoading: false,
      };
    }
    case RECEIVE_SITE: {
      const site = { ...action.site };
      if (!site.location) {
        site.location = {
          city: '',
          country: '',
          lat: 0,
          lng: 0,
        };
      }
      return {
        ...state,
        site,
        isLoading: false,
      };
    }
    case SUBMIT_SITE: {
      return {
        ...state,
        submitSuccess: null,
      };
    }
    case SUBMIT_SUCCESS: {
      return {
        ...state,
        submitSuccess: action.id,
      };
    }
    case DELETE_SITE: {
      return {
        ...state,
        deleteSuccess: false,
      };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
      };
    }

    default: {
      return state;
    }
  }
}

// Sagas

function* doRequestSites() {
  try {
    const token = yield call(getAccessToken, 'auth');
    const { values: sites } = yield getSites(token);
    yield put(receiveSites(sites));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSites([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doRequestSite({ id }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    const site = yield getSite(token, id);
    yield put(receiveSite(site));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSites([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doSubmitSite({ site }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    let response;
    if (site.id) {
      response = yield updateSite(token, site);
    } else {
      response = yield createSite(token, site);
    }
    yield put(submitSuccess(response.id));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSites([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doDeleteSite({ id }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    yield removeSite(token, id);
    yield put(deleteSuccess());
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSites([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

export const sagas = [
  takeLatest(REQUEST_SITES, doRequestSites),
  takeLatest(REQUEST_SITE, doRequestSite),
  takeLatest(SUBMIT_SITE, doSubmitSite),
  takeLatest(DELETE_SITE, doDeleteSite),
];
