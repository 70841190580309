const css = `.user-badge-component {
  display: flex;
  align-items: center;
}
.user-badge-component img {
  width: 1.3rem;
  height: 1.3rem;
  display: inline-block;
  border-radius: 50%;
  margin-right: 0.6rem;
  border: 0.2rem solid #cccccc;
}
.user-badge-component .title {
  position: relative;
  top: -0.05rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvVXNlckJhZGdlIiwic291cmNlcyI6WyJVc2VyQmFkZ2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIkBpcS9yZWFjdC1jb21wb25lbnRzL2Rpc3QvY29sb3JzLnNjc3NcIjtcblxuLnVzZXItYmFkZ2UtY29tcG9uZW50IHtcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcblxuICBpbWcge1xuICAgIHdpZHRoOiAxLjNyZW07XG4gICAgaGVpZ2h0OiAxLjNyZW07XG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAgIGJvcmRlci1yYWRpdXM6IDUwJTtcbiAgICBtYXJnaW4tcmlnaHQ6IDAuNnJlbTtcbiAgICBib3JkZXI6IDAuMnJlbSBzb2xpZCAkR3JleTIwO1xuICB9XG5cbiAgLnRpdGxlIHtcbiAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgdG9wOiAtMC4wNXJlbTtcbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
