import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Button, Can } from '@iq/react-components';

import EmptyState from '../EmptyState';
import Entity from '../Entity';
import EntityModal from '../EntityModal';
import SectionHeader from '../SectionHeader';

const entityDisplayName = ({ firstName, lastName, username }) =>
  firstName ? `${firstName} ${lastName}` : username;

class EntityTable extends React.Component {
  state = {
    modalOpen: false,
    redirect: null,
  };

  toggleModal = () => {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  };

  onEntityAdd = (eid) => {
    this.setState({ redirect: eid });
  };

  render() {
    const { etype, entities, users } = this.props;
    const { modalOpen, redirect } = this.state;

    if (redirect !== null) {
      return <Redirect to={`/entities/${etype}/${redirect}`} />;
    }

    return (
      <section className="entity-table">
        <SectionHeader title="Entities">
          <Can permission="permissions/Write">
            <Button
              activity="info"
              onClick={this.toggleModal}
            >
              Add entity
            </Button>
          </Can>
        </SectionHeader>

        {entities.length ? (
          <table>
            <thead>
              <tr>
                <th>Entity</th>
                <th colSpan="2">Name</th>
              </tr>
            </thead>
            <tbody>
              {entities.sort().map((entityRef) => {
                const eid = entityRef.split('/')[1];
                return (
                  <tr key={entityRef}>
                    <td>
                      <Entity eref={entityRef} />
                    </td>
                    <td>{users[eid] ? entityDisplayName(users[eid]) : '-'}</td>
                    <td className="actions">
                      <Link to={`/entities/${entityRef}`}>
                        <Button
                          activity="secondary"
                          design="outlined"
                        >
                          View
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <EmptyState>Nothing to display.</EmptyState>
        )}
        <EntityModal
          isOpen={modalOpen}
          etype={etype}
          onClose={this.toggleModal}
          onEntityAdd={this.onEntityAdd}
        />
      </section>
    );
  }
}

function mapStateToProps({ ad: { users } }) {
  return {
    users,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EntityTable);
