import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@iq/react-components';

import { submitSite } from '../../bundles/sites';
import { requestModels } from '../../bundles/models';

import Modal from '../Modal';

const SiteModal = ({
  isLoading,
  data,
  models,
  submitSite: submitSiteData,
  error,
  requestModels: getModels,
  submitSuccess,
  onClose,
  onSubmitSuccess,
}) => {
  const [name, setName] = useState(data ? data.name : '');
  const [customer, setCustomer] = useState(data ? data.customer : '');
  const [siteId, setSiteId] = useState(data ? data.siteId : '');
  const [org, setOrg] = useState(data ? data.org : '');
  const [selectedModel, setSelectedModel] = useState(data ? data.model : '');
  const [selectedVersion, setSelectedVersion] = useState(data ? data.version : '');
  const [city, setCity] = useState(data && data.location ? data.location.city : '');
  const [country, setCountry] = useState(data && data.location ? data.location.country : '');
  const [lat, setLat] = useState(data && data.location.lat);
  const [lng, setLng] = useState(data && data.location.lng);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const canSubmit = Boolean(
    name.length > 0 && siteId.length > 0 && city.length > 0 && country.length > 0 && lat && lng
  );

  useEffect(() => {
    getModels();
  }, []);

  const submit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const site = {
        ...data,
        name,
        siteId,
        model: selectedModel,
        version: selectedVersion,
        customer: customer.length ? customer : '',
        org: org.length ? org : '',
        location: {
          lat,
          lng,
          city: city.length ? city : '',
          country: country.length ? country : '',
        },
      };

      if (site.model === '') delete site.model;
      if (site.org === '') delete site.org;
      if (site.customer === '') delete site.customer;

      submitSiteData(site);
    }
  };

  if (submitSuccess !== null) {
    onSubmitSuccess(submitSuccess);
  }

  if (error !== null && error.length > 0 && isSubmitting) {
    setIsSubmitting(false);
  }

  let filteredModels = [];
  if (!isLoading && models && models.length && org) {
    filteredModels = [...models].filter((item) => item.org === org && !item.removed);
    filteredModels = filteredModels.map((modelItem) => {
      const nextModel = { ...modelItem };
      nextModel.versions = modelItem.versions.filter(
        (versionItem) => String(versionItem.status).toLowerCase() === 'completed'
      );
      return nextModel;
    });
    filteredModels = filteredModels.filter((item) => item.versions && item.versions.length > 0);
    filteredModels = filteredModels.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  let modelVersions = [];
  if (filteredModels.length) {
    const modelVersionsArr = filteredModels.filter((item) => item.id === selectedModel);
    if (modelVersionsArr.length) {
      modelVersions = (modelVersionsArr[0] || {}).versions;
    }
  }

  if (isLoading) {
    return (
      <Modal
        isOpen
        style="slim"
        onClose={onClose}
        title={data && data.id ? 'Edit site' : 'Add site'}
      >
        <h4>loading...</h4>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      style="slim"
      onClose={onClose}
      title={data && data.id ? 'Edit site' : 'Add site'}
    >
      <section className="sites--modal">
        {error !== null && error.length > 0 && (
          <div className="sites--modal-message">
            {error.length > 0 && error.map((msg) => <p key={msg}>{msg}</p>)}
          </div>
        )}
        <div className="sites--modal-form">
          <label className="sites--modal-field required">
            <span>Name:</span>
            <input
              disabled={isSubmitting}
              required
              name="site_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </label>
          <label className="sites--modal-field">
            <span>Customer:</span>
            <input
              disabled={isSubmitting}
              name="site_customer"
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
            ></input>
          </label>
          <label className="sites--modal-field required">
            <span>Site ID:</span>
            <input
              disabled={isSubmitting}
              required
              name="site_id"
              value={siteId}
              onChange={(e) => setSiteId(e.target.value)}
            ></input>
          </label>
          <label className="sites--modal-field required">
            <span>Org:</span>
            <input
              disabled={isSubmitting}
              name="site_org"
              value={org}
              onChange={(e) => setOrg(e.target.value)}
            ></input>
          </label>
          <label className="sites--modal-field">
            <span>Model:</span>
            <select
              disabled={filteredModels.length === 0}
              name="model"
              value={selectedModel}
              onChange={(e) => {
                setSelectedVersion('');
                setSelectedModel(e.target.value);
              }}
            >
              <option
                value=""
                disabled
              >
                {filteredModels.length === 0
                  ? '--- input a valid org to list its models ---'
                  : '--- click here to select ---'}
              </option>
              {filteredModels.map((item) => (
                <option
                  key={`model-${item.id}`}
                  value={item.id}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </label>
          <label className="sites--modal-field">
            <span>Version:</span>
            <select
              name="model-version"
              disabled={modelVersions.length === 0}
              value={selectedVersion}
              onChange={(e) => setSelectedVersion(e.target.value)}
            >
              <option
                value=""
                disabled
              >
                --- click here to select ---
              </option>
              {modelVersions.map((item) => (
                <option
                  key={`modelVersion-${item.id}`}
                  value={item.id}
                >
                  {item.versionNumber}
                </option>
              ))}
            </select>
          </label>
          <label className="sites--modal-field required">
            <span>City:</span>
            <input
              disabled={isSubmitting}
              required
              name="site_city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            ></input>
          </label>
          <label className="sites--modal-field required">
            <span>Country:</span>
            <input
              disabled={isSubmitting}
              required
              name="site_country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            ></input>
          </label>
          <label className="sites--modal-field required">
            <span>Latitude:</span>
            <input
              disabled={isSubmitting}
              name="site_lat"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
            ></input>
          </label>
          <label className="sites--modal-field required">
            <span>Longitude:</span>
            <input
              disabled={isSubmitting}
              name="site_lng"
              value={lng}
              onChange={(e) => setLng(e.target.value)}
            ></input>
          </label>
        </div>
        <br></br>
        <Button
          onClick={submit}
          disabled={!canSubmit || isSubmitting}
        >
          {data && data.id ? 'Edit site' : 'Add site'}
        </Button>
      </section>
    </Modal>
  );
};

function mapStateToProps({
  models: { isLoading, modelsList },
  errors: { error },
  sites: { submitSuccess },
}) {
  let messages = [];
  if (error && error !== null && error.message) {
    messages = [`${error.message.message}: `];
    if (
      error.errors &&
      error.errors.errors &&
      error.errors.errors.body &&
      error.errors.errors.body.length
    ) {
      messages = [...messages, ...error.errors.errors.body.map((item) => item.message)];
    }
  }
  return {
    isLoading,
    models: modelsList,
    error: messages.length ? messages : '',
    submitSuccess,
  };
}

const mapDispatchToProps = {
  requestModels,
  submitSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteModal);
