/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@iq/services';
import { getAccessToken } from '@iq/react-components';

import { getModels } from '../services';

import { handleGlobalError } from './errors';

// Action types

const RESET_STATE = 'admin/models/RESET_STATE';

const REQUEST_MODELS = 'admin/models/REQUEST_MODELS';
const RECEIVE_MODELS = 'admin/models/RECEIVE_MODELS';

// Action creators

export function requestModels() {
  return {
    type: REQUEST_MODELS,
  };
}

export function receiveModels(models) {
  return {
    type: RECEIVE_MODELS,
    models,
  };
}

// Initial state
const initialState = {
  modelsList: [],
  isLoading: false,
};

// Reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case REQUEST_MODELS: {
      return {
        ...state,
        modelsList: [],
        isLoading: true,
      };
    }
    case RECEIVE_MODELS: {
      return {
        ...state,
        modelsList: action.models,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

// Sagas

function* doRequestModels() {
  try {
    const token = yield call(getAccessToken, 'auth');
    const { values: models } = yield getModels(token);
    yield put(receiveModels(models));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveModels([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

export const sagas = [takeLatest(REQUEST_MODELS, doRequestModels)];
