import React from 'react';
import { Button, Can } from '@iq/react-components';

import PermissionModal from '../PermissionModal';
import AddPermissionModal from '../AddPermissionModal';
import EmptyState from '../EmptyState';
import SectionHeader from '../SectionHeader';
import Permission from '../Permission';
import Scope from '../Scope';

class EntityPermissionTable extends React.Component {
  state = {
    addModal: {
      isOpen: false,
    },
    editModal: {
      isOpen: false,
      permission: null,
    },
  };

  addPermission = (permission) => {
    const { etype, eid, grantPermission } = this.props;
    const { key, value } = permission;
    grantPermission(etype, eid, { [key]: JSON.parse(value) });
  };

  removePermission = (permission, object) => {
    const { etype, eid, revokePermission, permissions } = this.props;
    const objects = typeof object === 'undefined' ? permissions[permission] : [object];
    console.log(objects);

    // eslint-disable-next-line no-alert
    const confirmRemove = window.confirm(`
      Are you sure you want to remove permission ${permission} for ${etype} ${eid}
    `);

    if (confirmRemove) {
      revokePermission(etype, eid, {
        [permission]: objects,
      });
    }
  };

  displayEditModal = (permission) => () => {
    const { permissions } = this.props;

    this.setState({
      editModal: {
        permission: {
          key: permission,
          value: permissions[permission],
        },
        isOpen: true,
      },
    });
  };

  displayAddModal = () => {
    const addModal = { isOpen: true };
    this.setState({ addModal });
  };

  closeModal = () => {
    this.setState({
      editModal: {
        isOpen: false,
      },
    });
  };

  render() {
    const { eid, etype, permissions } = this.props;
    const eref = `${etype}/${eid}`;

    return (
      <section className="permissions-table">
        <SectionHeader title="Entity permissions">
          <Can
            permission="permissions/Write"
            scope={{ entity: eref }}
          >
            <Button
              activity="info"
              onClick={this.displayAddModal}
            >
              Add permission
            </Button>
          </Can>
        </SectionHeader>

        {Object.keys(permissions).length ? (
          <table>
            <thead>
              <tr>
                <th>Permission</th>
                <th colSpan="2">Scopes</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(permissions)
                .sort()
                .map((permission) => (
                  <tr key={permission}>
                    <td className="permission-name">
                      <Permission name={permission} />
                    </td>
                    <td>
                      {permissions[permission].map((scopeRef) => (
                        <Scope
                          key={`${permission}-${scopeRef}`}
                          scopeRef={scopeRef}
                        />
                      ))}
                    </td>
                    <td className="actions">
                      <Can
                        permission="permissions/Write"
                        scope={{ entity: eref }}
                      >
                        <Button
                          className="action"
                          design="outlined"
                          onClick={this.displayEditModal(permission)}
                        >
                          Edit
                        </Button>
                        <Button
                          activity="danger"
                          design="outlined"
                          onClick={() => this.removePermission(permission)}
                        >
                          Remove
                        </Button>
                      </Can>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <EmptyState>{eid} doesn't have any directly added permissions.</EmptyState>
        )}

        <AddPermissionModal
          onClickClose={() => {
            const addModal = { isOpen: false };
            this.setState({ addModal });
          }}
          onAddPermission={(permission, object) => {
            this.addPermission({ key: permission, value: object });
            const addModal = { isOpen: false };
            this.setState({ addModal });
          }}
          isOpen={this.state.addModal.isOpen}
        />

        <PermissionModal
          title={`Permissions for ${eref}`}
          isOpen={this.state.editModal.isOpen}
          onClickClose={this.closeModal}
          onClickAddPermission={this.addPermission}
          onClickRemovePermission={this.removePermission}
          permission={this.state.editModal.permission}
          perms={permissions}
        />
      </section>
    );
  }
}

export default EntityPermissionTable;
