import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Can } from '@iq/react-components';

import { deleteSource } from '../../bundles/sources';

import EmptyState from '../EmptyState';
import SectionHeader from '../SectionHeader';
import SourceModal from '../SourceModal';
import Modal from '../Modal';

const SourceTable = ({ error, sources, onDelete, deleteSource: submitDelete, deleteSuccess }) => {
  const [redirect, setRedirect] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  if (redirect !== null) {
    return <Redirect to={redirect} />;
  }

  if (deleteSuccess) {
    onDelete();
  }

  if (error !== null && error.length > 0 && isDeleting) {
    setIsDeleting(false);
  }

  const onSubmitDelete = (id) => {
    setIsDeleting(true);
    submitDelete(id);
  };

  return (
    <section className="source-table">
      <SectionHeader title="Sources">
        <Can permission="permissions/Write">
          <Button
            activity="info"
            onClick={() => setModalOpen(true)}
          >
            Add source
          </Button>
        </Can>
      </SectionHeader>
      {sources.length ? (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sources.sort().map(({ id, type }) => (
              <tr key={id}>
                <td>
                  <span className="source">
                    <Link to={`/sources/${id}`}>{id}</Link>
                  </span>
                </td>
                <td>{type}</td>
                <td className="actions">
                  <Link to={`/sources/${id}`}>
                    <Button
                      activity="secondary"
                      design="outlined"
                    >
                      View
                    </Button>
                  </Link>
                  <Button
                    activity="danger"
                    design="outlined"
                    onClick={() => setDeleteConfirm(id)}
                  >
                    Delete
                  </Button>
                </td>
                {deleteConfirm && deleteConfirm === id && (
                  <Modal
                    isOpen
                    style="slim"
                    onClose={() => setDeleteConfirm(undefined)}
                    title="Remove Source"
                  >
                    <section className="sources--modal">
                      {error !== null && error.length > 0 && (
                        <div className="sources--modal-message">
                          <p>{error}</p>
                        </div>
                      )}
                      <div className="sources--modal-prompt">
                        <p>Are you sure you want to remove this source?</p>
                        <div className="sources--modal-actions">
                          <Button
                            activity="danger"
                            disabled={isDeleting}
                            onClick={() => onSubmitDelete(id)}
                          >
                            Yes
                          </Button>
                          <Button
                            className="action cancel"
                            activity="secondary"
                            design="outlined"
                            disabled={isDeleting}
                            onClick={() => setDeleteConfirm(undefined)}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </section>
                  </Modal>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <EmptyState>Nothing to display.</EmptyState>
      )}
      {modalOpen && (
        <SourceModal
          onClose={() => setModalOpen(false)}
          onSubmitSuccess={(id) => setRedirect(`/sources/${id}`)}
        />
      )}
    </section>
  );
};

function mapStateToProps({ errors: { error }, sources: { deleteSuccess } }) {
  let message = '';
  if (error && error.name) {
    message = error.name;
  } else if (error && error.object && error.object.message) {
    // eslint-disable-next-line
    message = error.object.message;
  }
  return {
    deleteSuccess,
    error: message,
  };
}

const mapDispatchToProps = {
  deleteSource,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceTable);
