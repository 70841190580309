import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Button } from '@iq/react-components';

class SearchForm extends React.Component {
  state = {
    value: '',
    redirect: false,
  };

  render() {
    const { redirect, value } = this.state;
    const { redirectUri } = this.props;

    if (redirect) {
      return <Redirect to={redirectUri(value)} />;
    }

    return (
      <div className="search-form">
        <form
          className="entity-search-form"
          onSubmit={(e) => {
            e.preventDefault();
            if (value) {
              this.setState({ redirect: true });
            }
          }}
        >
          <div className="field">
            <input
              type="text"
              placeholder={this.props.placeHolder || 'Search...'}
              onChange={(e) => this.setState({ value: e.target.value })}
              value={this.state.value}
            />
          </div>
          <Button
            activity="secondary"
            design="outlined"
            type="submit"
          >
            Go
          </Button>
        </form>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
