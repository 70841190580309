const css = `.entity-search-form {
  display: flex;
  flex-direction: row;
}
.entity-search-form .field {
  margin: 0 0.5em 0 0;
  flex: 1;
}
.entity-search-form .field input[type=text] {
  font-size: 1em;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2VhcmNoRm9ybSIsInNvdXJjZXMiOlsiU2VhcmNoRm9ybS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBRUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJAaXEvcmVhY3QtY29tcG9uZW50cy9kaXN0L2NvbG9ycy5zY3NzXCI7XG5cbi5lbnRpdHktc2VhcmNoLWZvcm0ge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogcm93O1xuXG4gIC5maWVsZCB7XG4gICAgbWFyZ2luOiAwIDAuNWVtIDAgMDtcbiAgICBmbGV4OiAxO1xuXG4gICAgaW5wdXRbdHlwZT1cInRleHRcIl0ge1xuICAgICAgZm9udC1zaXplOiAxZW07XG4gICAgfVxuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
